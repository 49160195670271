/*=============================================================================================
    Document   : Stylesheet
    Author     : Your Name
==============================================================================================*/
/* helper
----------------------------------------------------------------------------------------------*/
// @import "../milligram/Color";    // import your general color from milligram

/* general
----------------------------------------------------------------------------------------------*/
@import "../component/layout";
@import "../pages/general";

/* home styling
----------------------------------------------------------------------------------------------*/
.trigger {
	position: absolute;
	height: 1px;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
}
.wrapper.mobile {
	display: none;
}
.slider-home-mobile {
	display: none;
}
.slider-home,.slider-home-mobile {
	position: relative;
	.caption {
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		.box {
			width: 520px;
			background: $color-initial;
			box-shadow: 2px 2px 10px 0 rgba(50,230,170,0.5);
			padding: 40px;
			margin-left: auto;
			border-radius: 30px;
			.img {
				width: 250px;
				margin-bottom: 20px;
			}
		}
	}
	.slider-wrap {
		.slide {
			position: relative;
			height: 100%;
			figure {
					position: relative;
					img {
						width: 100%;
						display: block;
					}
			}
		}
	}
	.slick-dots {
		position: absolute;
		left: 0;
		right: 0;
		z-index: 3;
		top: auto;
		bottom: 20px;
		display: flex;
		justify-content: center;
		margin: 0;
		li {
			margin: 0 5px;
			&.slick-active {
				button {
					background: $color-primary;
					width: 30px;
					transition: all ease-out 0.25s;
				}
			}
			button {
				padding: 0;
				font-size: 0;
				height: 12px;
				width: 12px;
				background: $color-initial;
				transition: all ease-out 0.25s;
				&:hover {
					transition: all ease-out 0.25s;
					background: $color-primary;
				}
			}
		}
	}
}
#news-highlight {
	padding-bottom: 0;

}
.section-partner {
	position: relative;
	padding: 15px 0;
	.wrapper {
		display: flex;
		align-items: center;
		justify-content: space-around;
		&.mobile {
			display: none;
		}
		a {
			width: 100%;
			display: block;
			img {
				display: block;
				transition: all ease-out 0.25s;
				position: relative;
				top: 0;
				max-width: 240px;
			}
			&.logo-main {
				width: 240px;
				margin-right: 100px;
			}
			&:hover {
				img {
					top: -3px;
					transition: all ease-out 0.25s;
				}
			}
		}
	}
}

.full-banner {
	position: relative;
	padding: 40px 0;
	figure {
		position: relative;
		&:after {
			position: absolute;
			content: '';
			width: 100%;
			left: 0;
			right: 0;
			top: -8px;
			bottom: -8px;
			background: rgb(15,137,207,1);
			background: linear-gradient(90deg, rgba(15,137,207,1) 0%, rgba(42,206,190,1) 100%);
			margin: auto;
			border-radius: 0px;
			z-index: 1;
		}
		&:before {
			position: absolute;
			content: '';
			width: 100%;
			max-width: 1200px;
			left: 0;
			right: 0;
			top: -15px;
			bottom: -15px;
			background: rgb(15,137,207,1);
			background: linear-gradient(90deg, rgba(15,137,207,1) 0%, rgba(42,206,190,1) 100%);
			margin: auto;
			border-radius: 30px;
			z-index: 2;
		}
		img,video {
			width: 100%;
			position: relative;
			z-index: 2;
		}
		
	}
}

.home-news {
	padding: 60px 0;
	position: relative;
	.list-news {
		margin-bottom: 25px;
		.slick-slide {
			padding: 10px 15px;
		}
		.item-news {
			&.highlight {
				display: flex !important;
				margin: 0;
			}
		}
		.slick-arrow {
			right: -45px;
			left: auto;
			&.slick-prev {
				right: auto;
				left: -45px;
			}
		}
	}
}
.about-home {
	position: relative;
	padding: 60px 0;
	.wrapper {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		figure {
			width: 580px;
			img {
				width: 100%;
			}
		}
		.text-about {
			width: calc(100% - 580px);
			padding-left: 80px;
		}
	}
	.logo-kmntrian {
		display: flex;
		.logo-main {
			width: 240px;
		}
		.other {
			width: calc(100% - 240px);
			display: flex;
			flex-wrap: wrap;
			a {
				width: 45%;
				justify-content: space-between;
				img {
					max-width: 80px;
					margin: auto;
					display: block;
				}
			}
		}
	}
}
.industry-lanscape {
  position: relative;
  padding: 200px 0 180px;
  overflow: hidden;
	background: url('/images/blue-top.png') no-repeat top center,url('/images/blue-btm.png') no-repeat bottom center;
	background-size: 100%, 100%;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		background: url('/images/blue-center.png') repeat-y;
		background-size: 100%;
		display: block;
		width: 100%;
		left: 0;
		right: 0;
		height: 55%;
		margin: auto;
	}
  .wrapper {
		display: flex;
		flex-wrap: wrap;
		padding-top: 30px;
		.title-section {
			width: 100%;
		}
		.text-map {
			width: calc(100% - 680px);
			padding-right: 30px;
			* {
				color: $color-initial;
			}
		}
		figure {
			width: 680px;
			margin-top: -40px;
		}
	}
	.title-section {
		h3 {
			background: none;
			span {
				background: #125c93;
				padding-right: 30px;
			}
		}
	}
}
.contact-home {
	padding: 120px 0 100px;
	background: url('/images/bg-contact-us.png') no-repeat bottom -10px center;
	background-size: 100%;
	position: relative;
	&:after {
		content: '';
		background: rgb(255,255,255);
		background: linear-gradient(180deg, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 100%);
		position: absolute;
		height: 100px;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
	.box {
		// background: rgba(15,137,207,.5);
		background: rgb(10,97,166);
		background: linear-gradient(185deg, rgba(10,97,166,0.85) 3%, rgba(48,149,209,0.85) 100%);
		border-radius: 30px;
		padding: 80px 50px 50px;
		h3 {
			font-size: 2,125rem;
			text-align: center;
			color: $color-initial;
			margin-bottom: 30px;
		}
		.box-form {
			width: 640px;
			margin: auto;
			label {
				color: $color-initial;
				text-align: left;
			}
			.row {
				.column {
					margin-bottom: 15px;
					&.wrap-btn {
						text-align: center;
						.button {
							min-width: 200px;
						}
						.sign-up {
							color:#fff;
							text-decoration: underline;
							&:hover {
								text-decoration: none;
							}
						}
						
					}
				}
			}
			
		}
	}
}


/* responsive desktop
----------------------------------------------------------------------------------------------*/
// @media all and (max-width: 1600px) {}



/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1279px) {
	.slider-home  {
		.caption {
			.box {
				width: 400px;
				padding: 35px;
				.img {
					width: 200px;
				}
				h3 {
					margin-bottom: 15px;
				}
			}
		}
		.slider-wrap {}
	}

	.about-home {
		.wrapper {
			figure {
				width: 350px;
			}
			.text-about {
				width: calc(100% - 350px);
			}
		}
	}
	.home-news {
		.list-news {
			.slick-arrow {
				right: -35px;
				&.slick-prev {
					left: -35px;
					right: auto;
				}
			}
		}
	}

	.industry-lanscape {
    padding: 150px 0;
    &:before {
      top: 210px;
      bottom: 170px;
    }
		.wrapper {
			figure {
				width: 500px;
			}
			.text-map {
				width: calc(100% - 500px);
			}

		}
  }
	.contact-home {
		padding-top: 60px;
	}
}



/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {

	.slider-home  {
		.caption {
			.box {
				width: 300px;
				padding: 30px;
				.img {
					width: 150px;
				}
				p {
					display: none;
				}
			}
		}
		.slider-wrap {
			.slide {
				.caption {
					.box {
						width: 300px;
						padding: 30px;
						.img {
							width: 150px;
						}
						p {
							display: none;
						}
					}
				}
			}
		}
	}

	.section-partner {
		padding: 12px 0;
		.wrapper {
			a {
				width: 80px;
				margin: 0 10px;
				&.logo-main {
					width: 200px;
					margin-right: 80px;
				}
			}
		}
	}

	.full-banner {
		padding: 30px 0;
	}
	.home-news {
		padding: 30px 0;
		.list-news {
			.slick-arrow {
				right: -24px;
				&.slick-prev {
					left: -24px;
					right: auto;
				}
			}
		}
	}
	.industry-lanscape  {
    padding: 140px 0 ;
    &:before {
      top: 0;
      bottom: 0;
			height: 80%;
    }
		.wrapper {
			.title-section {
				order: 1;
				-webkit-order: 1;
			}
			figure {
				order: 2;
				-webkit-order: 2;
				width: 100%;
				max-width: 500px;
				margin: 0 auto 30px;
			}
			.text-map {
				order: 3;
				-webkit-order: 3;
				width: 100%;
			}
		}
  }
	.about-home {
		.wrapper {
			figure {
				width: 100%;
				max-width: 400px;
				margin: auto;
			}
			.text-about {
				padding-top: 30px;
				width: 100%;
				padding-left: 0;
			}
		}
	}
	.contact-home {
		.box {
			.box-form {
				width: 100%;
			}
		}
	}
}



/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
	.slider-home-mobile {
		display: block;
	}
	.slider-home {
		display: none;
	}
	.slider-home-mobile  {
		.caption {
			position: relative;
			margin-top: -60%;
			top: 0;
			transform: translateY(0);
			.box {
				width: 100%;
				padding: 20px;
				text-align: center;
				.img {
					width: 150px;
					margin: auto;
					margin-bottom: 12px;
				}
			}
		}
		.slider-wrap {
			.slide {
				.caption {
					position: relative;
					margin-top: -20px;
					top: 0;
					transform: translateY(0);
					.box {
						width: 100%;
						padding: 20px;
						text-align: center;
						.img {
							width: 150px;
							margin: auto;
							margin-bottom: 12px;
						}
					}
				}
			}
			.slick-dots {
				bottom: 45%;
				li {
					button {
						margin: 0;
						width: 10px;
						height: 10px;
						background: #ccc;
					}
					&.slick-active {
						button {
							width: 20px;
							background: $color-primary;
						}
					}
				}
			}
		}
	}
	.section-partner {
		padding: 20px 0 12px;
		.wrapper {
			flex-wrap: wrap;
			a {
				margin: 0 0 15px;
				width: 50%;
				padding: 0 10px;
				&.logo-main {
					width: 150px;
					margin-right: 40px;
				}
				img {
					max-width: 100%;
				}
			}
		}
	}
	.industry-lanscape {
    padding: 100px 0;
		&:before {
			height: 90%;
		}
  }

	.about-home {
		.logo-kmntrian {
			flex-wrap: wrap;
			justify-content: center;
			.logo-main {
				width: 180px;
			}
			.other {
				width: 100%;
			}
		}
	}

	.contact-home {
		.box {
			padding: 45px 24px 30px;
		}
	}
}
@media all and (max-width: 560px) {
	.section-partner {
		.wrapper {
			a {
				width: 50%;
				padding: 0 10px;
				&.logo-main {
					width: 50%;
					margin-right: 0;
				}
			}
			.kemendag {
				display: none;
			}
			&.mobile {
				display: flex;
			}
		}
		
	}
 }

 