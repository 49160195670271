@import "../milligram/Color";    // import your general color from milligram
/* header
----------------------------------------------------------------------------------------------*/
header{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 11;
  background: $color-initial;
  transition: all ease-out .3s;
  > .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
  }
  .logo {
    width: 230px;
    padding: 9px 0;
    img {
      display: block;
    }
  }
  .burger {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 28px;
    height: 21px;
    display: none;
    span {
      height: 3px;
      position: absolute;
      background: $color-primary;
      border-radius: 2px;
      left: auto;
      right: 0;
      top: 0;
      margin: auto;
      width: 100%;
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 9px;
      }
      &:nth-child(3) {
        top: 18px;
        width: 75%;
      }
    }
  }
  nav {
    position: relative;
    ul {
      display: flex;
      margin: 0;
      li {
        margin: 0 15px;
        a {
          color: $color-tertiary;
          transition: all ease-out 0.25s;
          font-weight: 500;
          letter-spacing: 0;
          &:hover,
          &.active {
            transition: all ease-out 0.25s;
            color: $color-primary;
          }
          &.active {
            font-weight: 500;
          }
        }
        &.have-child {
          position: relative;
          > a {
            i {
              position: relative;
              transform: rotate(90deg);
              margin-left: 5px;
              font-size: .75rem;
            }
          }
          ul {
            position: absolute;
            top: 90%;
            padding: 35px 0px 15px;
            width: 200px;
            z-index: 8;
            left: 50%;
            transform: translateX(-50%);
            display: none;
            transition: all ease-out 0.25s;
            &:before {
              content: '';
              background: #fff;
              box-shadow: 0 0 15px -2px rgba(50,230,170,.5);
              left: 0;
              right: 0;
              bottom: 0;
              top: 20px;
              border-radius: 6px;
              position: absolute;
              z-index: 1;
              overflow: hidden;
            }
            li {
              margin: 0 auto 8px;
              text-align: center;
              position: relative;
              z-index: 2;
              a {
                display: block;
                padding: 5px;
              }
              &:hover {
                background-color: #f2f2f2;
              }
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          &:hover {
            > a {
              color: $color-primary;
            }
            ul {
              transition: all ease-out 0.25s;
              display: block;
            }
          }
        }
        
      }
      &.menu-after {
        justify-content: flex-end;
        padding-top: 12px;
        display: none;
        li {
          padding: 0 15px;
          margin: 0;
          background: #eaeaea;
          &:first-child {
            background: transparent;
          }
          &:nth-child(2) {
            border-radius: 6px 0 0 6px;
          }
          &:last-child {
            border-radius: 0 6px 6px 0;
          }
          a {
            padding: 5px 30px 10px;
            display: block;
            position: relative;
            &::before {
              content: '';
              height: 4px;
              border-radius: 2px;
              width: 0px;
              left: 30px;
              bottom: 5px;
              top: auto;
              background: $color-primary;
              position: absolute;
              transition: all ease-out 0.25s;
            }
            &:hover,
            &.active {
              &:before {
                transition: all ease-out 0.25s;
                width: 24px;
              }
            }
          }
        }
      }
    }
  }
  .main-header {
    display: flex;
    align-items: center;
  }
  .head-button {
    margin-left: 20px;
    display: flex;
    align-items: center;
    .lang {
      margin-right: 15px;
      font-size: .875rem;
      a {
        font-size: .875rem;
        color: #333;
        &.active {
          color: $color-primary;
        }
      }
    }
    &.mobile {
      display: none;
    }
    .button {
      margin: 0;
      padding: 0 20px;
    }
    .user {
      position: relative;
      color: $color-tertiary;
      .ico {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 auto -6px;
        cursor: pointer;
        img {
          display: block;
        }
      }
      > i {
        font-size: .625rem;
        width: 16px;
        height: 16px;
        line-height: 16px;
        background: #ee1616;
        border-radius: 50%;
        color: $color-initial;
        position: absolute;
        font-style: normal;
        text-align: center;
        right: 0%;
        left: auto;
        top: 0;
      }
      > span {
        padding: 10px 15px;
        display: block;
        background: #eaeaea;
        line-height: 20px;
        border-radius: 6px;
        max-width: 120px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .drop-user {
        position: absolute;
        top: calc(100% + 15px);
        padding: 15px 20px;
        background: #fff;
        box-shadow: 0 0 15px -2px rgba(50,230,170,.5);
        width: 150px;
        border-radius: 6px;
        z-index: 8;
        right: 0;
        left: auto;
        overflow: hidden;
        display: none;
        ul {
          margin: 0;
          li {
            margin: 0 0 6px;
            a {
              font-weight: 500;
              color: #333;
              font-size: .875rem;
              display: block;
              &:hover {
                color: $color-primary;
              }
            }
          }
        }
      }
      &.drop {
        .drop-user {
          display: block;
        }
      }
    }
  }
  .search-header {
    background: linear-gradient(90deg, rgba(15,137,207,.6) 0%, rgba(42,206,190,.6) 100%);
    position: relative;
    .wrapper {
      position: relative;
    }
    .lang {
      width: 125px;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      align-items: center;
      i {
        position: absolute;
        font-size: .75rem;
        left: 10px;
        top: 17px;
      }
      select {
        background-color: #fff;
        height: 26px;
        padding: 0 30px;
        border: none;
        margin: 0;
        background-size: 12px;
        background-position: right 8px center;
        font-size: .875rem;
        border-radius: 4px;
      }
    }
    form {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 0;
      input[type="text"] {
        background: $color-initial;
        margin: 0;
        width: 600px;
        border: none;
        height: 26px;
        line-height: 26px;
        &::placeholder {
          font-style: italic;
          color: #fff;
        }
      }
      label {
        font-size: .875rem;
        font-weight: 600;
        color: $color-initial;
        margin: 0;
        line-height: 1;
        margin-right: 20px;
      }
      button {
        background: none;
        margin: 0;
        padding: 0 20px;
        height: 24px;
        line-height: 24px;
        color: $color-initial;
        i {
          font-size: 1rem;
        }
        &:hover {
          background: none;
        }
      }

    }
  }
}


.popup-container {
  .box-form {
    .column {
      display: flex;
      margin-bottom: 20px;
      label {
        width: 150px;
        margin-bottom: 0;
        padding-right: 20px;
        text-align: left;
      }
      input[type="text"],
      input[type="email"],
      input[type="password"],
      select {
        width: calc(100% - 150px);
        border: 2px solid $color-primary;
        border-radius: 20px;
        font-size: .875rem;
        height: 40px;
        line-height: 40px;
        margin: 0;
      }
      &.wrap-btn {
        flex-wrap: wrap;
        justify-content: center;
        padding-left: 150px;
        .button {
          min-width: 200px;
        }
        p {
          width: 100%;
          margin: 0;
          a {
            text-decoration: underline;
            color: $color-tertiary;
            &:hover {
              color: $color-primary;
            }
          }
        }
      }
    }
  }
  &#popup-login {
    .popup-content {
      width: 640px;
    }
    .wrap-logo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      a {
        max-width: 68px;
        &.logo-main {
          max-width: 180px;
        }
        img {
          display: block;
        }
      }
    }
  }
}



/* footer
----------------------------------------------------------------------------------------------*/
footer{
  background: $color-primary;
  padding: 40px 0;
  font-size: 0.9rem;
  h6 {
        color: $color-initial;
        margin-bottom: 10px;
      }
  .wrapper {
    display: flex;
    justify-content: space-between;
  }
  .col-left {
    display: flex;
    width: 600px;
    .logo-footer {
      width: 600px;
      color:white;
    }
    .text-footer {
      padding-left: 30px;
      width: calc(100% - 220px);
      
      p {
        color: $color-initial;
        margin: 0;
        opacity: .8;
      }
    }
  }
  .col-nav-footer {
    width: 240px;
    ul {
      margin: 0;
      li {
        margin: 0;
        a {
          color: $color-initial;
          display: block;
          &:hover{
            opacity: .8;
          }
        }
      }
    }
  }
}






/* responsive desktop
----------------------------------------------------------------------------------------------*/
// @media all and (max-width: 1600px) {}



/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1279px) {
  header {
    .logo {
      width: 140px;
      padding: 17px 0;
    }
    nav {
      ul {
        justify-content: flex-end;
        li {
          margin: 0 10px;
          a {
            font-size: .938rem;
          }
        }
        &.menu-after {
          li {
            padding: 0 5px;
            a {
              line-height: 1;
              padding: 6px 20px 10px;
              &:before {
                height: 3px;
                bottom: 3px;
                left: 20px;
              }
            }
          }
        }
      }
    }
    .head-button {
      .button {
        padding: 0 20px;
      }
      .user {
        > span {
          padding: 6px 10px;
          font-size: .938rem;
          max-width: 100px;
        }
      }
    }
    .search-header form input[type=text] {
      width: 500px;
    }
  }


  footer {
    .col-left {
      width: calc(100% - 320px);
      .text-footer {
        p {
          font-size: .875rem;
        }
      }
    }
  }


}



/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  header {
    .wrapper {
      padding: 6px 0;
    }
    .logo {
      width: 120px;
      padding: 3px 0 2px;
    }
    .burger {
      display: block;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 8;
      span {
        transition: all ease-out 0.25s;

      }
      &.collapse {
        span {
          transition: all ease-out 0.25s;
          background: $color-initial;
          &:nth-child(1) {
            top: 20px;
            transform: rotate(45deg);
          }
          &:nth-child(3) {
            top: 20px;
            transform: rotate(-45deg);
            width: 100%;
          }
          &:nth-child(2) {
            width: 0;
            opacity: 0;
          }
        }
      }
    }
    nav {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $color-primary;
      z-index: 7;
      padding: 80px 60px 40px;
      visibility: hidden;
      opacity: 0;
      transition: all ease-out .3s;
      transform: scale(.9);
      .menu {
        max-height: calc(100vh - 120px);
        overflow: auto;
      }
      ul {
        display: block;
        li {
          text-align: left;
          margin-bottom: 12px;
          a {
            color: $color-initial;
            font-size: 1.25rem;
            &:hover {
              color: $color-initial;
            }
          }
          &.have-child {
            > a {
              i {
                font-size: 1rem;
                transition: all ease-out 0.25s;
                display: none;
              }
            }
            ul {
              transition: none;
              position: relative;
              display: block;
              width: auto;
              left: 0;
              right: 0;
              margin: auto;
              transform: translateX(0);
              padding: 10px;
              transition: none;
              &:before {
                display: none;
              }
              li {
                text-align: left;
                a {
                  font-size: 1rem;
                  color: #fff !important;
                  padding: 0 5px;
                  font-weight: normal;
                }
                &:hover {
                  background:none;
                }
              }
            }
            &.drop {
              > a {
                i {
                  transform: rotate(-90deg);
                  transition: all ease-out 0.25s;
                }
              }
            }
            &:hover {
              > a {
                color: #fff;
              }
              ul {
                // display: none;
                transition: none;
              }
            }
          }
        }
        &.menu-after {
          border-top: 1px dashed rgba(255,255,255,.4);
          margin-top: 35px;
          padding-top: 30px;
          display: block;
          li {
            background: transparent;
            margin-bottom: 8px;
            a {
              padding-bottom: 12px;
              &:before {
                background: $color-initial;
                left: 0;
                right: 0;
                margin: auto;
              }
              &.active {
                color: $color-initial;
              }
            }
          }
        }
      }
    }
    .main-header {
      &.act {
        nav {
          transition: all ease-out 0.3s;
          visibility: visible;
          opacity: 1;
          transform: scale(1);
        }
      }
    }
    .head-button {
      margin-left: 0;
      margin-right: 40px;
      display: none;
      .user {
        display: flex;
        align-items: center;
        width: 40px;
        margin: auto;
        .ico {
          width: 40px;
          height: 40px;
          margin: auto;
        }
        > i {
          left: 24px;
          top: -3px;
        }
        > span {
          padding: 0;
          font-size: .875rem;
          max-width: 100px;
          padding-left: 15px;
          background: transparent;
          padding-top: 5px;
          max-width: 90px;
        }
      }
      &.mobile {
        text-align: center;
        display: block;
        margin-right: 0;
        border-top: 1px dashed rgba(255, 255, 255, 0.4);
        margin-top: 35px;
        padding-top: 30px;
        .button {
          background: $color-initial;
          color: $color-primary;
        }
        .user {
          .drop-user {
            left: 50%;
            right: auto;
            transform: translateX(-50%);
          }
        }
      }
    }
    .search-header {
      form {
        padding: 5px 0;
        justify-content: flex-start;
        input[type="text"] {
          width: 375px;
        }
        button {
          padding: 0 10px;
        }
      }
    }
  }





  footer {
    .col-left {
      width: calc(100% - 240px);
      .logo-footer {
        width: 140px;
      }
      .text-footer {
        padding-left: 20px;
        width: calc(100% - 140px);
        p {
          font-size: .875rem;
        }
      }
    }
    .col-nav-footer {
      width: 200px;
      ul {
        li {
          a {
            font-size: .875rem;
          }
        }
      }
    }
  }
  
}



/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .hidden-mobile {
    display: none!important;
  }
  header {
    .logo {
      width: 100px;
    }
    nav {
      padding: 80px 60px 40px;
      ul {
        li {
          a {
            font-size: 1.125rem;
            font-weight: bold;
          }
        }
      }
    }
    .search-header {
      .lang {
        width: 110px;
        i {
          left: 7px;
          top: 18px;
        }
        select {
          font-size: .75rem;
          padding: 0 20px;
          padding-left: 24px;
          background-position: right 8px center;
        }
      }
      form {
        label {
          font-size: .75rem;
          display: none;
        }
        input[type="text"] {
          width: calc(100% - 150px);
          &::placeholder {
            color: #ccc;
          }
        }
      }
    }
  }
  

  footer {
    .wrapper {
      flex-wrap: wrap;
    }
    .col-left {
      width: 100%;
      display: block;
      margin-bottom: 30px;
      text-align: center;
      .logo-footer {
        width: 100%;
        margin: 0 auto 20px;
        
        a {
          clear: both;
          float: none!important;
          text-align: center;
          img {
            display: inline;
          }
        }
      }
      .text-footer {
        padding-left: 0px;
        width: 100%;
        text-align: center;
        p {
          font-size: .875rem;
        }
      }
    }
    .col-nav-footer {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
      ul {
        li {
          text-align: center;
          a {
            font-size: .833rem;
          }
        }
      }
    }
  }
  .popup-container {
    .box-form {
      .column {
        flex-wrap: wrap;
        label {
          width: 100%;
        }
        input[type="text"],
        input[type="email"],
        input[type="password"],
        select {
          width: 100%;
        }
        &.wrap-btn {
          padding-left: 0;
        }
      }
    }
   &#popup-login {
      .popup-content {
        width: calc(100vw - 48px);
        padding: 35px 25px;
        padding-top: 60px;
      }
      .wrap-logo {
        flex-wrap: wrap;
        a {
          width: 15%;
          &.logo-main {
            width: 40%;
            img {
              max-width: 100%;
            }
          }
          img {
            display: block;
            max-width: 90%;
            margin: auto;
          }
        }
      }
   }
  
  }
}