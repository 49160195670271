@import "../milligram/Color";    // import your general color from milligram

/* general styling
----------------------------------------------------------------------------------------------*/
.loader {
	bottom: 0;
	left: 0;
	overflow: hidden;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 999999;
	.innerLoad {
		align-items: center;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		position: relative;
		z-index: 5;
		.line-load {
			border-radius: 4px;
      margin-top: 8px;
			height: auto;
			left: 0;
			position: relative;
			right: 0;
			top: 0;
			width: 160px;
			z-index: 9;
      padding: 2px 3px;
      background: #eaeaea;
			&:before,
			span {
				height: 3px;
        border-radius: 2px;
			}
			&:before {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				width: 100%;
				background: transparent;
				z-index: 1;
				
			}
			span {
				background: linear-gradient(90deg, rgba(15,137,207,1) 0%, rgba(42,206,190,1) 100%);
				border-radius: 2px;
				display: block;
				width: 0;
				position: relative;
				z-index: 2;
        overflow: hidden;
			}
		}
	}
  .counter-loading {
    font-size: .75rem;
    display: block;
    margin-top: 4px;
    font-weight: 600;
    color: $color-primary;

  }
	.bg-load {
    background: #fff;
    display: block;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
	}
}


.middle {
  position: relative;
  padding-top: 145px;
}
.main-pages-content {
  padding-top: 40px;
  position: relative;
  padding-bottom: 100px;
}
.title-section {
  display: flex;
  margin-bottom: 35px;
  &.center {
    justify-content: center;
    h3 {
      background: url('/images/bg-rh-title.png') no-repeat right 15px center ,  url('/images/bg-lf-title.png') no-repeat left 15px center;
      padding: 0 50px;
      span {
        padding: 0 80px;
        text-align: center;
      }
    }
  }
  h3 {
    padding-right: 50px;
    background: url('/images/bg-rh-title.png') no-repeat right 15px center ;
    margin: 0;
    span {
      display: block;
      line-height: 50px;
      color: $color-initial;
      background: #0093de;
      padding: 0 80px;
      padding-left: 30px;
      border-radius: 25px;
    }
  }
}

.tag-result {
  span {
    color: $color-primary;
    font-weight: 500;
  }
}

.list-news {
  display: flex;
  flex-wrap: wrap;
  .col {
    padding: 0 10px;
  }
  .col-50 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-55 {
    flex: 0 0 55%;
    max-width: 55%;
  }
  .col-45 {
    flex: 0 0 45%;
    max-width: 45%;
    .item-news {
      figure {
        img {
          max-height: 150px;
        }
      }
    }
  }
  .col-60 {
    flex: 0 0 60%;
    max-width: 60%;
  }
  .col-40 {
    flex: 0 0 40%;
    max-width: 40%;
  }
}
.item-news {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 2px 2px 10px 0 rgba(50,230,170,0.5);
  margin-bottom: 20px;
  margin:20px 10px;
  &:hover {
    figure {
      img {
        transition: all ease-out 0.25s;
        transform: scale(1.05);
      }
    }
  }
  figure {
    display: block;
    position: relative;
    overflow: hidden;
    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
      transition: all ease-out 0.25s;
    }
    a {
      display: block;
      height: 100%;
    }
    &:after {
      height: 5px;
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(90deg, rgba(15,137,207,1) 0%, rgba(42,206,190,1) 100%);
      top: auto;
      display: block;
    }
  }
  .desc-news {
    padding: 20px;
    .tag-news {
      font-size: 1.25rem;
      font-weight: 500;
      color: $color-primary;
      display: block;
      margin-bottom: 10px;
    }
    h3 {
      font-size: 1.25rem;
      a {
        color: $color-tertiary;
        &:hover {
          color: $color-primary;
        }
      }
    }
    .more {
      font-weight: 600;
      font-size: .875rem;
      &:hover {
        color: $color-secondary;
      }
    }
    .w-btn {
      .button {
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
  &.big {
    .desc-news {
      padding: 40px;
      h3 {
        font-size: 1.5rem;
      }
    }
  }
  &.highlight {
    display: flex;
    figure {
      width: 60%;
      &:after {
        top: 0;
        bottom: 0;
        width: 8px;
        height: 100%;
        left: auto;
        right: 0;
      }
    }
    .desc-news {
      width: 40%;
      padding: 40px 30px;
      padding-left: 40px;
      h3 {
        font-size: 1.75rem;
      }
    }
  }
}

.slick-arrow {
  width: 60px;
  height: 60px;
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: auto;
  right: 0;
  background: url('/images/ic-arrow.svg') no-repeat center;
  margin: auto;
  font-size: 0;
  z-index: 2;
  border-radius: 50%;
  background-size: 100%;
  &:hover {
    background-color: transparent;
  }
  &.slick-prev {
    transform: rotate(180deg);
    left: 0; 
    right: auto;
  }
}


.breadcrumb {
  position: relative;
  margin-bottom: 30px;
  ul {
    margin: 0;
    display: flex;
    li {
      margin: 0;
      padding-right: 25px;
      margin-right: 15px;
      position: relative;
      &:after {
        content: '>';
        position: absolute;
        right: 0;
      }
      &:last-child {
        margin-right: 0;
        padding-right: 0;
        &:after {
          display: none;
        }
      }
      a {
        color: $color-tertiary;
        &.active {
          font-weight: 500;
        }
      }
    }
  }
}

.banner-page {
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  margin-bottom: 50px;
  height: 340px;
  > img {
    height: 100%;
    width: 100%;
    display: block;
    object-fit: cover;
  }
}
.search-block {
  display: flex;
  justify-content: space-between;
  .col {
    &.lang {
      width: 320px;
      border-radius: 20px;
      overflow: hidden;
      position: relative;
      > select {
        display: block;
        height: 100%;
        object-fit: cover;
      }
      > span {
        width: 200px;
        height: 200px;
        border: 10px solid #ddd;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        overflow: hidden;
        border-radius: 50%;
        background: $color-initial
      }
    }
    &.search {
      width: calc(100% - 320px - 20px);
      display: flex;
    }
  }
}

.block-feature {
  display: flex;
  justify-content: space-between;
  .col {
    &.img {
      width: 320px;
      border-radius: 20px;
      overflow: hidden;
      position: relative;
      > img {
        display: block;
        height: 100%;
        object-fit: cover;
      }
      > span {
        width: 200px;
        height: 200px;
        border: 10px solid #ddd;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        overflow: hidden;
        border-radius: 50%;
        background: $color-initial
      }
      
    }
    .badge {
        background-color: #2eb85c;
        color: #fff;
        font-size: 11px;
        padding: 2px 10px;
        border-radius: 15px;
        display: inline-block;
    }
    &.desc-rh {
      width: calc(100% - 320px - 20px);
      padding: 35px;
      background: #DCF3FF;
      border-radius: 20px;
      display: flex;
      .lf {
        width: calc(100% - 200px);
        padding-right: 40px;
        h3 {
          margin-bottom: 5px;
        }
        > span {
          font-weight: 500;
          display: block;
          color: $color-primary;
          margin-bottom: 15px;
        }
        p{
          height: 105px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .badge {
          background-color: #2eb85c;
          color: #fff;
          font-size: 11px;
          padding: 2px 10px;
          border-radius: 15px;
          display: inline-block;
        }
      }
      .rh {
        width: 200px;
        p {
          span {
            display: block;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.pagination {
  position: relative;
  padding-top: 30px;
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      margin: 0 12px;
      a {
        font-weight: 500;
        color: $color-tertiary;
        &:hover,
        &.active {
          color: $color-primary;
        }
        &.button {
          color: $color-initial;
          margin-bottom: 0;
        }
      }
    }
  }
}


.popup-container {
	visibility: hidden;
	opacity: 0;
	transition: all 0.3s ease-in-out;
	position: fixed;
	z-index: 12;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(21, 17, 17, 0.9);
	display: flex;
	align-items: center;
	overflow: auto;
	max-height: 100%;
	.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1;
    width: 100%;
    height: 100%;
	}
  .close-pop {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 20px;
    right: 20px;
    cursor: pointer;
    transition: all ease-out 0.2s;
    &:hover {
      transition: all ease-out 0.2s;
      transform: scale(.9);
    }
    &:before,
    &:after {
      content: '';
      height: 3px;
      width: 80%;
      background: #333;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
	.popup-content {
		margin: auto;
		padding: 50px;
		border: none;
		width: 700px;
		box-sizing: border-box;
		min-height: 300px;
		position: relative;
		z-index: 2;
		background: #fff;
    text-align: center;
    border-radius: 16px;
    transform: scale(.9);
    transition: all 0.3s ease-in-out;
	}
	&.show {
		visibility: visible;
    opacity: 1;
    transform: scale(1);
    .popup-content {
      transform: scale(1);
    }
	}
  &#popup-gallery {
    .popup-content {
      width: 1100px;
      padding: 10px;
      border-radius: 0;
    }
  }
  &#popup-success {
    .popup-content {
      min-height: 200px;
      width: 460px;
    }
  }

}

/* responsive desktop
----------------------------------------------------------------------------------------------*/
// @media all and (max-width: 1600px) {}



/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1279px) {
  .middle {
    padding-top: 136px;
  }
  .banner-page {
    height: 300px;
  }
  .item-news {
    &.highlight {
      figure {
        width: 50%;
      }
      .desc-news {
        width: 50%;
        padding: 25px;
        .tag-news {
          font-size: 1rem;
        }
        h3 {
          font-size: 1.375rem;
        }
      }
    }
  }
  .block-feature {
    .col {
      &.img {
        align-self: flex-start;
      }
      &.desc-rh {
        .lf {
          padding-right: 24px;
        }
      }
    }
  }
  .popup-container {
    &#popup-gallery {
      .popup-content {
        width: 980px;
      }
    }
  }
}



/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  .middle {
    padding-top: 96px;
  }
  .main-pages-content {
    padding: 30px 0 60px;
  }
  .banner-page {
    height: 260px;
  }
  .title-section {
    h3 {
      font-size: 1.125rem;
      background-size: 40px, 40px;
      background-position: right 23px center;
      span {
        line-height: 1.4;
        padding: 6px 40px;
        padding-left: 20px;
      }
    }
    &.center {
      h3 {
        background-size: 40px, 40px;
        background-position: right 23px center, left 23px center;
        span {
          padding: 6px 30px;
        }
      }
    }
  }
  .list-news {
    .col {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }
  .item-news {
    &.big {
      .desc-news {
        padding: 24px;
        h3 {
          font-size: 1.25rem;
        }
      }
    }
    .desc-news {
      h3 {
        font-size: 1.125rem;
      }
    }
    &.highlight {
      flex-wrap: wrap;
      figure {
        width: 100%;
        &:after {
          height: 5px;
          left: 0;
          right: 0;
          bottom: 0;
          top: auto;
          width: 100%;
        }
      }
      .desc-news {
        width: 100%;
      }
    }
  }
  .slick-arrow {
    width: 40px;
    height: 40px;
    background-size: 100%;
  }
  .breadcrumb {
    ul {
      flex-wrap: wrap;
      li {
        padding-right: 15px;
        margin-right: 10px;
        line-height: 1.2;
        margin-bottom: 5px;
        &:after {
          font-size: .813rem;
          vertical-align: top;
          top: 2px;
        }
        a {
          font-size: .875rem;
          display: block;
          
        }
      }
    }
  }
  .block-feature {
    .col {
      &.img {
        width: 240px;
      }
      &.desc-rh {
        width: calc(100% - 240px - 15px);
        flex-wrap: wrap;
        .lf {
          width: 100%;
          padding-right: 0;
        }
        .rh {
          width: 100%;
          margin-top: 30px;
        }
      }
    }
  }
  .popup-container {
    &#popup-gallery {
      .popup-content {
        width: 680px;
      }
    }
  }
}



/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .list-news {
    display: flex;
    flex-wrap: wrap;
  }
  .main-pages-content {
    padding: 30px 0 40px;
  }
  .banner-page {
    margin-bottom: 30px;
    height: 220px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      min-height: 150px;
    }
  }
  .title-section {
    h3 {
      font-size: 1.125rem;
      background-size: 30px, 30px;
      background-position: right 27px center;
      span {
        line-height: 1.2;
        padding: 6px 40px;
        padding-left: 20px;
      }
    }
    &.center {
      h3 {
        background-size: 30px, 30px;
        background-position: right 27px center, left 27px center;
        span {
          padding: 6px 30px;
        }
      }
    }
  }
  .middle {
    padding-top: 92px;
  }
  .item-news {
    &.big {
      .desc-news {
        padding: 20px;
        h3 {
          font-size: 1.125rem;
        }
      }
    }
    .desc-news {
      padding: 20px;
      h3 {
        font-size: 1rem;
      }
    }
  }
  .slick-arrow {
    width: 32px;
    height: 32px;
  }
  .search-block {
    .col {
      &.lang {
        width: 220px;
      }
      &.search {
        width:calc(100% - 220px - 20px); ;
      }
    }
  }
  .block-feature {
    flex-wrap: wrap;
    .col {
      &.img {
        width: 100%;
      }
      &.desc-rh {
        width: 100%;
        margin-top: 20px;
        padding: 25px 20px;
      }
    }
  }
  .list-result {

    .block-feature {
      .col {
        &.img {
          width: 100%;
        }
      }
    }
  }
  .pagination ul li a.button {
    font-size: 0;
    padding: 0 8px;
    i {
      font-size: 1rem;
      line-height: 32px;
      text-align: center;
      display: inline-flex;
      margin-left: 5px;
      &::before {
        display: block;
        text-align: center;
      }
    }
    &.next {
      i {
        margin-left: 0;
        margin-right: 5px;
      }
    }
  }
  .popup-container {
    &#popup-gallery {
      .popup-content {
        width: calc(100vw - 48px);
        margin: 0 24px;
        min-height: 220px;
        .slider-gallery {
          figure {
            img {
              object-fit: cover;
              min-height: 220px;
              width: 100%;
              display: block;
            }
          }
        }
      }
    }
    &#popup-success {
      .popup-content {
        padding: 60px 25px 20px;
      }
    }
  }
}

@media all and (max-width: 560px) {
  .banner-page {
    height: 160px;
  }
  .popup-container {
    &#popup-success {
      .popup-content {
        width: 100%;
        max-width: calc(100vw - 48px);
        margin: auto;
      }
    }
  }
}